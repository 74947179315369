import React from "react";
import {
  TextInput,
  LinkSettings,
  List,
  TextArea,
  SelectMenu,
} from "@raketa-cms/raketa-cms";
import FloatingInput from "./FloatingInput";

const TAG_TEMPLATE = { name: "Design" };
const ICON_TEMPLATE = { src: "https://placeholder.raketa.cloud/images/28x28" };

const TEMPLATE = {
  link: LinkSettings.defaults,
  image: "https://placeholder.raketa.cloud/images/260x72", // 32:9
  description: "Lorem ipsum",
  tag: "Save 50%",
  elements_order: "icons-title",
  show_arrow: "yes",
  icons: [{ ...ICON_TEMPLATE, id: 1 }],
  tags: [{ ...TAG_TEMPLATE, id: 1 }],
};

const TagsInput = ({ value, onChange }) => {
  return (
    <List
      label="Tags"
      listItem={(settings, onChangeItem) => (
        <TextInput
          label="Name"
          onChange={(value) => onChangeItem("name", value)}
          value={settings.name}
        />
      )}
      onChangeList={(_, newList) => onChange(newList)}
      items={value}
      primaryField="name"
      template={TAG_TEMPLATE}
    />
  );
};

const IconsInput = ({ value, onChange }) => {
  return (
    <List
      label="Icons"
      listItem={(settings, onChangeItem) => (
        <TextInput
          label="Source"
          onChange={(value) => onChangeItem("src", value)}
          value={settings.src}
        />
      )}
      onChangeList={(_, newList) => onChange(newList)}
      items={value}
      primaryField="src"
      template={ICON_TEMPLATE}
    />
  );
};

const CardsInput = ({ value, onChange }) => {
  return (
    <List
      label="Cards"
      listItem={(settings, onChangeItem) => (
        <>
          <SelectMenu
            label="Icons - title ordering"
            options={[
              ["icons-title", "1. Icons, 2. Title"],
              ["title-icons", "1. Title, 2. Icons"],
            ]}
            value={settings.elements_order}
            onChange={(value) => onChangeItem("elements_order", value)}
          />

          <SelectMenu
            label="Show card arrow"
            options={[
              ["yes", "Yes"],
              ["no", "No"],
            ]}
            value={settings.show_arrow}
            onChange={(value) => onChangeItem("show_arrow", value)}
          />

          <LinkSettings
            label="Link"
            value={settings.link}
            onChange={(value) => onChangeItem("link", value)}
            hint="The link label is used as the card title."
          />

          <TextInput
            label="Image"
            onChange={(value) => onChangeItem("image", value)}
            value={settings.image}
          />

          <TextArea
            label="Description"
            onChange={(value) => onChangeItem("description", value)}
            value={settings.description}
          />

          <TextInput
            label="Tag (over the image)"
            onChange={(value) => onChangeItem("tag", value)}
            value={settings.tag}
          />

          <IconsInput
            value={settings.icons}
            onChange={(value) => onChangeItem("icons", value)}
          />

          <TagsInput
            value={settings.tags}
            onChange={(value) => onChangeItem("tags", value)}
          />
        </>
      )}
      onChangeList={(_, newList) => onChange(newList)}
      items={value}
      primaryField="link.label"
      template={TEMPLATE}
    />
  );
};

const HighlightsInput = ({ value, onChange }) => {
  return (
    <FloatingInput label="Edit highlights">
      <TextInput
        label="Title"
        onChange={(newValue) => onChange({ ...value, title: newValue })}
        value={value.title}
      />

      <CardsInput
        onChange={(newValue) => onChange({ ...value, cards: newValue })}
        value={value.cards}
      />
    </FloatingInput>
  );
};

HighlightsInput.defaults = {
  title: "Recommended",
  cards: [
    {
      id: 1,
      ...TEMPLATE,
    },
  ],
};

export default HighlightsInput;
